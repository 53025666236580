// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-faqs-index-js": () => import("./../../../src/pages/about/faqs/index.js" /* webpackChunkName: "component---src-pages-about-faqs-index-js" */),
  "component---src-pages-about-mission-index-js": () => import("./../../../src/pages/about/mission/index.js" /* webpackChunkName: "component---src-pages-about-mission-index-js" */),
  "component---src-pages-about-nana-index-js": () => import("./../../../src/pages/about/nana/index.js" /* webpackChunkName: "component---src-pages-about-nana-index-js" */),
  "component---src-pages-about-partners-index-js": () => import("./../../../src/pages/about/partners/index.js" /* webpackChunkName: "component---src-pages-about-partners-index-js" */),
  "component---src-pages-about-process-index-js": () => import("./../../../src/pages/about/process/index.js" /* webpackChunkName: "component---src-pages-about-process-index-js" */),
  "component---src-pages-adopt-dogs-index-js": () => import("./../../../src/pages/adopt/dogs/index.js" /* webpackChunkName: "component---src-pages-adopt-dogs-index-js" */),
  "component---src-pages-adopt-happy-tails-index-js": () => import("./../../../src/pages/adopt/happy-tails/index.js" /* webpackChunkName: "component---src-pages-adopt-happy-tails-index-js" */),
  "component---src-pages-credits-js": () => import("./../../../src/pages/credits.js" /* webpackChunkName: "component---src-pages-credits-js" */),
  "component---src-pages-foster-index-js": () => import("./../../../src/pages/foster/index.js" /* webpackChunkName: "component---src-pages-foster-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-dog-js": () => import("./../../../src/templates/dog.js" /* webpackChunkName: "component---src-templates-dog-js" */),
  "component---src-templates-happy-tail-js": () => import("./../../../src/templates/happy-tail.js" /* webpackChunkName: "component---src-templates-happy-tail-js" */)
}

